import React from 'react';
import { Col, Row, Button } from 'antd';
import PropTypes from 'prop-types';
import Container from '../components/Container';
import Layout from '../components/Layout';
import ProductsBanner from '../components/ProductsBanner';
import VideoTextCard from '../components/VideoTextCard';
import CpmContentList from '../components/CpmContentList';
import HomeLogos from '../components/HomeLogos';
import BlockIconText from '../components/BlockIconText';
import CpmRound from '../images/cpm-round.svg';
import CpmBanner from '../images/cpm-banner-image.png';
import TitledVerticalBoxes from '../components/TitledVerticalBoxes';
import VerticalBox from '../components/VerticalBox';
import CpmHistory from '../components/CpmHistory';
import { useSearch } from '../utils/common';

const CPM_DATA = [
  {
    id: '01',
    title: '配置同意采集工具',
  },
  {
    id: '02',
    title: '在各渠道应用上部署同意采集工具',
  },
  {
    id: '03',
    title: '采集同意与偏好授权数据',
  },
  {
    id: '04',
    title: '将数据适配于营销业务',
  },
  {
    id: '05',
    title: '接收并响应用户请求',
  },
  {
    id: '06',
    title: '数据分析与优化',
  },
];

const CpmItem = ({ id, title }) => {
  return (
    <div className="cpm-list-item">
      <div className="cpm-list-item-no-circle">{id}</div>
      <div className="cpm-list-item-content-wrapper">{title}</div>
    </div>
  );
};

CpmItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
};

export default () => {
  const search = useSearch();
  const href = '/whitepaper/';
  const hash = '403';

  const linkStr = `${href}${search}#${hash}`;

  const onItemClick = () => {
    if (window) {
      window.open(linkStr, '_blank');
    }
  };

  return (
    <Layout current="products" subCurrent="cpm">
      <ProductsBanner
        sectionId="cpm"
        title="CPM 同意与偏好管理平台"
        description="随着欧洲GDPR、美国CCPA等隐私法的相继施行，个人信息保护已成为全球共识。而国内《个保法》正式施行，也标志着中国迈入了加速立法保护消费者数字权利的新纪元。Convertlab CPM是专为满足企业进行「同意与偏好管理」的平台，可实现从获得同意、管理同意、优化同意率，到最终服务于用户请求和营销策略的全生命周期管理，助力企业从数字化建设底座层面实现安全合规。"
        img={CpmBanner}
        video="//convertlab-website.oss-cn-hangzhou.aliyuncs.com/videos/cpm.mp4"
        videoId="cl-cpm"
        cover="//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/video-cover-cpm.png"
        extraButton={
          <Button ghost className="common-button-large download-whitePaper" onClick={onItemClick}>
            白皮书下载
          </Button>
        }
      />
      <VideoTextCard
        title="产品+运营咨询服务的综合解决方案"
        cover="//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/video-cover-cpm-mini.jpg"
        video="//convertlab-website.oss-cn-hangzhou.aliyuncs.com/videos/cpm-mini.mov"
      >
        <BlockIconText
          content="用户交互：提供多种采集工具并记录授权信息，内容上进行个性化配置和A/B测试"
          color="blue"
        />
        <BlockIconText
          content="核心服务：提供存储数据、处理数据、规则适配等核心能力，通过数据分析优化有效刺激用户进行授权同意，实现授权同意最佳实践"
          color="green"
        />
        <BlockIconText
          content="管理服务：通过规则引擎管理同意数据，进行有效性验证、优先级管理，帮助解决不同渠道同意信息冲突"
          color="blue"
        />
        <BlockIconText
          content="集成服务：丰富的API接口、消息订阅等开放能力，与其它业务系统进行集成应用"
          color="green"
        />
      </VideoTextCard>
      <CpmHistory />
      <CpmContentList />
      <TitledVerticalBoxes title="五大优势" subTitle="从数字化建设底座层面实现安全合规" shadowed>
        <Col className="icon-text-col same" lg={8} md={12} sm={24}>
          <VerticalBox
            iconIndex={98}
            description="一体化解决方案一站配齐"
            description2="前端工具、后台管理、第三方平台对接一站配齐"
            smLayout="in-row"
          />
        </Col>
        <Col className="icon-text-col same" lg={8} md={12} sm={24}>
          <VerticalBox
            iconIndex={99}
            description="帮助提高同意授权率"
            description2="提供A/B测试等功能面向不同人群进行适配"
            smLayout="in-row"
          />
        </Col>
        <Col className="icon-text-col same" lg={8} md={12} sm={24}>
          <VerticalBox
            iconIndex={100}
            description="广泛行业的法律咨询服务"
            description2="从法条解读到业务实际，再到组织运营优化的合规管理法律咨询服务"
            smLayout="in-row"
          />
        </Col>
        <Col className="icon-text-col same" lg={8} md={12} sm={24}>
          <VerticalBox
            iconIndex={101}
            description="全线产品数据安全合规"
            description2="无缝集成现有Convertlab全链路营销云产品矩阵"
            smLayout="in-row"
          />
        </Col>
        <Col className="icon-text-col same" lg={8} md={12} sm={24}>
          <VerticalBox
            iconIndex={102}
            description="保持遵循相关政策合规"
            description2="产品设计、技术及功能层面保持更新，与相关政策的合规要求保持一致"
            smLayout="in-row"
          />
        </Col>
      </TitledVerticalBoxes>
      <section className="cpm-round-wrapper">
        <div className="title-wrapper">
          <h3 className="">提供完整的同意与偏好管理能力</h3>
        </div>
        <Container>
          <Row className="content-row">
            <img src={CpmRound} alt="提供完整的同意与偏好管理能力" />
          </Row>
          <Row className="content-list-wrapper">
            {CPM_DATA.map((item) => (
              <CpmItem key={item.id} {...item} />
            ))}
          </Row>
        </Container>
      </section>
      <HomeLogos />
    </Layout>
  );
};
