import React from 'react';
import Container from './Container';
import ImageText from './ImageText';
import ImageTextDescription from './ImageTextDesctiption';
import Cpm1 from '../images/cpm-1.svg';
import Cpm2 from '../images/cpm-2.svg';
import CpmIcon1 from '../images/cpm-icon-1.svg';
import CpmIcon2 from '../images/cpm-icon-2.svg';
import CpmIcon3 from '../images/cpm-icon-3.svg';

export default () => {
  return (
    <div className="content-list-wrapper cpm">
      <div>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText
              image={Cpm1}
              imgWidth={485}
              imgHeight={430}
              title="从法律合规到营销质量实现完整业务升级"
            >
              <ImageTextDescription label="满足PIPL等相关政策法规的要求，帮助企业快速实现法律合规" />
              <ImageTextDescription label="建立合规、严谨的品牌形象，增进用户信任，持续收获品牌好感度" />
              <ImageTextDescription label="提供符合用户偏好的营销沟通与服务，用户体验更具人性化、个性化" />
              <ImageTextDescription label="基于同意数据指向更高用户意向，高质量数据与洞察可提升长期ROI" />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText
              image={Cpm2}
              imgWidth={522}
              imgHeight={387}
              position="right"
              title="多渠道采集信息工具"
              title3="在前端帮助企业收集用户同意与偏好数据"
            >
              <ImageTextDescription
                content={
                  <span className="cpm-description-item-wrapper">
                    <img src={CpmIcon1} alt="网页Cookie的同意收集工具" />
                    网页Cookie的同意收集工具
                  </span>
                }
              />
              <ImageTextDescription
                content={
                  <span className="cpm-description-item-wrapper">
                    <img src={CpmIcon2} alt="APP的同意收集SDK" />
                    APP的同意收集SDK
                  </span>
                }
              />
              <ImageTextDescription
                content={
                  <span className="cpm-description-item-wrapper">
                    <img src={CpmIcon3} alt="小程序的同意收集SDK" />
                    小程序的同意收集SDK
                  </span>
                }
              />
            </ImageText>
          </Container>
        </section>
      </div>
    </div>
  );
};
