import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Container from './Container';
import CpmHistoryMap from '../images/cpm-history-map.svg';
import CpmHistoryMobile from '../images/cpm-history-map-mobile.svg';

const HISTORY_LIST = [
  {
    date: '2012-12-28',
    content: '全国人大关于加强网络信息保护的决定',
  },
  {
    date: '2013-02-01',
    content: 'GB/Z28828-2012\n信息安全技术\n公共及商用服务信息系统\n个人信息保护指南',
  },
  {
    date: '2013-09-01',
    content: '电信和互联网用户个人信息保护决定',
  },
  {
    date: '2015-11-01',
    content: '刑法修正案（九）',
  },
  {
    date: '2017-06-01',
    content: '网络安全法',
  },
  {
    date: '2017-06-01',
    content: '最高人民法院、最高人民检察院关于办理侵犯公民个人信息刑事案件适用法律若干问题的解释',
  },
  {
    date: '2017-08',
    content: '信息安全技术\n数据出境安全评估指南（征求意见稿）',
  },
  {
    date: '2019-04',
    content: '互联网个人信息安全保护指南',
  },
  {
    date: '2019-05-28',
    content: '数据安全管理办法（征）',
  },
  {
    date: '2019-06-13',
    content: '个人信息出境安全评估办法（征）',
  },
  {
    date: '2019-08-08',
    content: '信息安全技术\n移动互联网应用程序（APP）收集个人信息基本规范（征）',
  },
  {
    date: '2019-10-01',
    content: '儿童个人信息网络保护规定',
  },
  {
    date: '2019-11-28',
    content: 'APP违法违规收集使用个人信息行为认定方法',
  },
  {
    date: '2020-01-01',
    content: '密码法',
  },
  {
    date: '2020-01',
    content: '信息安全技术\n个人信息告知同意指南（征求意见稿）',
  },
  {
    date: '2020-02-01',
    content: '国家政务信息化项目建设管理办法',
  },
  {
    date: '2020-06-01',
    content: '网络安全审查办法',
  },
  {
    date: '2020-08-28',
    content: '信息安全技术\n网络数据处理安全规范（征求意见稿）',
  },
  {
    date: '2020-10-01',
    content: 'GB/T 35273-2020\n信息安全技术 个人信息安全规范',
  },
  {
    date: '2021-05-01',
    content: '常见类型移动互联网应用程序（APP）必要个人信息范围规定',
  },
  {
    date: '2021-08-01',
    content: '最高人民法院关于审理使用人脸识别技术处理个人信息相关民事案件适用法律若干问题的规定',
  },
  {
    date: '2021-09-01',
    content: '数据安全法',
  },
  {
    date: '2021-11-01',
    content: '个人信息保护法',
  },
  {
    date: '2021-11-14',
    content: '网络数据安全管理条例\n（征求意见稿）',
  },
];

const itemWidth = 250; // 每一个日期块占 250px
const minX = 0;
const rowItemCount = 4; // 一行 4 项
const maxX = itemWidth * (HISTORY_LIST.length - rowItemCount);
const stepX = itemWidth * rowItemCount;

const HistorySlotItem = ({ date, content }) => {
  return (
    <div className="history-slot-list-item">
      <div className="date">
        <div className="date-content">{date}</div>
      </div>
      <div className="slot" />
      <div className="content">{content}</div>
    </div>
  );
};

HistorySlotItem.propTypes = {
  date: PropTypes.string,
  content: PropTypes.string,
};

export default () => {
  const [scrollX, setScrollX] = useState(maxX);

  const handleScrollLeft = () => {
    if (minX === scrollX) {
      return;
    }
    setScrollX((currentX) => {
      const newX = currentX - stepX;
      if (newX <= minX) {
        return minX;
      }
      return newX;
    });
  };

  const handleScrollRight = () => {
    if (maxX === scrollX) {
      return;
    }
    setScrollX((currentX) => {
      const newX = currentX + stepX;
      if (newX >= maxX) {
        return maxX;
      }
      return newX;
    });
  };

  return (
    <section className="cpm-history-section">
      <Container fluid="xl">
        <h3 className="common-main-title">国内个人信息保护立法，在激流勇进</h3>
        <div className="shadowed-box">
          2021年11月1日，《个保法》正式实施，标志着中国继欧洲GDPR、美国CCPA等隐私法之后，迈入加速立法保护消费者数字权利的新纪元，中国企业迎来了海外企业曾经面对的难题 —— 数据合规
        </div>
        <img className="cpm-history-map" src={CpmHistoryMap} alt="cpm-history-map" />
        <img className="cpm-history-map-mobile" src={CpmHistoryMobile} alt="cpm-history-map" />
        <div className="history-line-title">中国数据安全之路</div>
        <div className="history-line-container">
          <div
            className={`left history-btn ${minX === scrollX ? 'disabled' : ''}`}
            onClick={handleScrollLeft}
          />
          <div className="history-line-row">
            {/* line 的长度要根据历史数据数组长度计算 */}
            <div className="line" />
            <div className="history-slot-list" style={{ transform: `translateX(-${scrollX}px)` }}>
              {HISTORY_LIST.map((item, index) => (
                <HistorySlotItem key={item.date + index} {...item} />
              ))}
            </div>
          </div>
          <div
            className={`right history-btn ${maxX === scrollX ? 'disabled' : ''}`}
            onClick={handleScrollRight}
          />
        </div>
      </Container>
    </section>
  );
};
